@import-normalize;

* {
  box-sizing: border-box;
}

body {
  /* part of getting the height right on mobile */
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  margin: 0;
}

#root {
  height: 100%;
  font-family: Roboto;

  /* override weird 1.15 line-height set by normalize.css. Causes weird vertical
     scrolling with Roboto font */
  line-height: normal;
}
